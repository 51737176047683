<template>
  <base-section
    id="international-collaboration"
    space="36"
    style="background:linear-gradient(#ffffff, #f4f4f4);"
  >
    <v-container>
      <base-section-heading title="Technology Collaboration" />
      <v-row>
        <base-img
          :src="require(`@/assets/cn.png`)"
          color="grey"
          contain
          height="160"
          class="mx-auto"
        />
      </v-row>
      <br>
      <br>
      <br>
      <v-row>
        <v-col
          cols="6"
        >
            <base-img
              :src="require(`@/assets/flag_korea.png`)"
              color="grey"
              contain
              height="120"
              class="mx-auto"
            />
        </v-col>
        <v-col
          cols="6"
        >
            <base-img
              :src="require(`@/assets/flag_japan.png`)"
              color="grey"
              contain
              height="120"
              class="mx-auto"
            />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionInternationalCollaboration',
  }
</script>
